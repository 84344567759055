import { graphql } from 'gatsby';
import * as React from 'react';
import ContentList from "../components/ContentList";
import Layout from "../components/layout";
import SEO from "../components/seo";
import parseBlogPost from "../parsers/blog-post";
export interface BlogIndexProps {
}

export default function BlogIndex ({data}: any) {
  const blogPostList = data.allMarkdownRemark.nodes.map(parseBlogPost);

  return (
    <Layout>
      <SEO title="Billets | Yann Rocq" />
      <ContentList content={blogPostList} />
    </Layout>
  );
}

export const Query = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 1000) {
      nodes {
        frontmatter {
          title
          Lieu
          date
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 410) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
        html
        excerpt
      }
      pageInfo {
        perPage
        itemCount
      }
    }
  }
`
