import { format } from 'date-fns';

interface Group {
  label?: string
  key: string
  items: any[]
}

type GroupedList = Group[]

export function groupBy<T>(list: T[], keyCallback: (item: any) => string, labelCallback: (item: any) => string): GroupedList {
  let currentGroup: Group
  let groupedList: GroupedList = []

  list.forEach(
    (item) => {
      const newKey = keyCallback(item)

      if (currentGroup?.key !== newKey) {
        currentGroup = {
          key: newKey,
          label: labelCallback(item),
          items: []
        }
        groupedList.push(currentGroup)
      }
      currentGroup['items'].push(item)
    }
  )

  return groupedList
 }
