import React from "react";
import Meta from '../meta'
import { FaPenAlt } from "react-icons/fa" 
import  { dateFormatLong, dateFormatMonthYear, dateFormatYearMonth } from '../../utils/date'
import BlogPostType from "../../types/BlogPost";
import Img from "gatsby-image"
import { Link } from "gatsby";
import {groupBy} from "../../utils/list"
import { capitalizeFirstLetter } from "../../utils/string";
import BlogPost from "../BlogPost";

export interface Props {
  content: BlogPostType[]
};

const ContentList: React.FC<Props> = (props) => (
  <div className="columns is-multiline">
  {
    groupBy(
      props.content,
      (item) => dateFormatYearMonth(item.publishedAt),
      (item) => capitalizeFirstLetter(dateFormatMonthYear(item.publishedAt))
    )
    .map(
      (group) => (
        <>
        <div className="column is-full" >
          <h2 className="is-size-3">
          {group.label || group.key}
          </h2>
          </div>
          {
            group.items.map(
              (item) => (
                <div className="column is-half">
                  <Link to={item.link}><BlogPost blogPost={item} excerpt={true}/></Link>
                </div>
              )
            )
          }
        </>
      )
    )
  }
  </div>
)
//   groupBy(
//     props.content,
//     (item) => dateFormatYearMonth(item.publishedAt),
//     (item) => capitalizeFirstLetter(dateFormatMonthYear(item.publishedAt))
//   )
//   .map(
//     (group) => (
//       <div class="column is-full" >
//         <h2>
//           {group.label || group.key}
//         </h2>
//         {

//         }
//       </div>
//     )
//   )
// )

export default ContentList;
